@import 'stylesShared/scss/_base';

.root {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.label {
  margin: 0;
}

.textarea {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.28px;
  word-wrap: break-word;

  ::placeholder {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.28px;
    word-wrap: break-word;
  }
}

.dropzoneWrapper {
  min-height: 100px;
  max-width: 800px;
  border: 2px dashed #d2ab64;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.title {
  padding: 20px;
  height: 20px;
}

.subtitle {
  padding: 0px 20px 20px;
  height: 20px;
}

.previewsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.previewWrapper {
  max-width: 100%;
  max-height: 240px;
  padding-left: 20px;
}

.preview {
  border: 2px solid #d2ab64;
  border-radius: 5px;
  max-width: 200px;
  max-height: 200px;
  margin-bottom: 10px;
}

.fileName {
  height: 20px;
  margin-bottom: 10px;
  color: black;
}
