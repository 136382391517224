.root {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.reviews {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.itemWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  p {
    margin-bottom: 3px;
  }
}

.titleRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  font-size: 20px;
  font-weight: 600;
}

.approved {
  color: #06c270;
  background-color: #afe0cb;
  padding: 5px;
  border-radius: 10px;
}

.rejected {
  color: #f43f36;
  background-color: #f5c2bf;
  padding: 5px;
  border-radius: 10px;
}

.onReview {
  color: #550969;
  background-color: #cdb6d4;
  padding: 5px;
  border-radius: 10px;
}

.dateRow {
  display: flex;
}

.dateLabel {
  width: 200px;
}

.dateValue {
  display: inline-block;
}

.feedback {
  padding: 1px 10px 5px;
}

.mediaWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.link {
  padding-left: 10px;
}
