@import 'stylesShared/scss/_base';

.root {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.label {
  margin: 0;
}

.value {
  margin-left: 20px;
}

.textarea {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.28px;
  margin-bottom: 15px;
  word-wrap: break-word;

  ::placeholder {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.28px;
    word-wrap: break-word;
  }
}

.previewWrapper {
  max-width: 100%;
  max-height: 240px;
  padding-left: 20px;
}

.previewsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.preview {
  border: 2px solid #d2ab64;
  border-radius: 5px;
  max-width: 200px;
  max-height: 200px;
  margin-bottom: 10px;
}

.fileName {
  height: 20px;
  margin-bottom: 10px;
  color: black;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .button {
    width: 250px;
  }
}
